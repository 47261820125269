<template>
    <div class="container">
        <div class="form">
            <label>
                <i class="fa fa-user"></i>
                <input class="input text" type="text" :placeholder="$t('m.joinAgent.contact_name')">
            </label>
            <label>
                <i class="fa fa-phone"></i>
                <input class="input text" type="text" v-model="mobile" :placeholder="$t('m.joinAgent.contact_phone')">
            </label>
            <label>
                <i class="fa fa-rmb"></i>
                <select class="input text">
                    <option value="0" selected>{{$t('m.joinAgent.contact_capital')}}</option>
                    <option value="1">{{$t('m.joinAgent.capital_option_a')}}</option>
                    <option value="2">{{$t('m.joinAgent.capital_option_b')}}</option>
                    <option value="3">{{$t('m.joinAgent.capital_option_c')}}</option>
                    <option value="4">{{$t('m.joinAgent.capital_option_d')}}</option>
                </select>
            </label>
            <label>
                <i class="fa fa-building"></i>
                <select class="input text" @change="onRegionChanged">
                    <option value="0" v-if="$store.state.regions[$i18n.locale].length === 0">
                        {{$t('m.joinAgent.region')}}
                    </option>
                    <option :value="region.id" v-bind:key="index"
                            v-for="(region,index) in $store.state.regions[$i18n.locale]"
                            :selected="region.id === $store.state.chosenArea.city_id">
                        {{region.name}}
                    </option>
                </select>
            </label>
            <label>
                <i class="fa fa-building-o"></i>
                <select class="input text" ref="areaSelector" @change="onAreaChange">
                    <option value="0" v-if="areas.length === 0">{{$t('m.joinAgent.area')}}</option>
                    <option :value="area.id" v-show="area.state === 0" v-bind:key="index" v-for="(area,index) in areas"
                            :selected="area.id === $store.state.chosenArea.id">{{area.name}}
                    </option>
                </select>
            </label>
            <label>
                <button class="input button">{{$t('m.submit')}}</button>
            </label>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'AgentInfo',
        data() {
            return {
                areas: [],
                mobile: '',
                money: '1'
            };
        },
        methods: {
            loadAreaData() {
                if (Object.keys(this.$store.state.chosenArea).length === 0) {
                    this.$store.state.chosenArea = this.$store.state.regions[this.$i18n.locale][0].area[0]
                }
                for (let i = 0; i < this.$store.state.regions[this.$i18n.locale].length; i++) {
                    let curRegion = this.$store.state.regions[this.$i18n.locale][i]
                    if (curRegion.id === this.$store.state.chosenArea.city_id) {
                        this.areas = curRegion.area
                        break
                    }
                }
            },
            onRegionChanged(val) {
                for (let i = 0; i < this.$store.state.regions[this.$i18n.locale].length; i++) {
                    let curRegion = this.$store.state.regions[this.$i18n.locale][i]
                    if (curRegion.id === val.target.value) {
                        this.areas = curRegion.area
                        this.$refs.areaSelector.childNodes[1].selected = true
                        break
                    }
                }
            },
            onAreaChange(val) {
                for (let i = 0; i < this.areas.length; i++) {
                    let curArea = this.areas[i]
                    if (curArea.id == val.target.value) {
                        this.$store.state.chosenArea = curArea
                    }
                }
            }
        },
        mounted() {
            this.loadAreaData()
        }
    }
</script>

<style scoped>
    .form {
        padding: 20px;
    }

    .form label {
        display: flex;
        position: relative;
        height: 40px;
        line-height: 40px;
        margin-bottom: 20px;
    }

    .form label .input {
        display: block;
        appearance: none;
        flex: 1;
        height: 40px;
        border-radius: 20px;
        background: transparent;
        padding-left: 40px;
        border: none;
    }

    .form label .input.text {
        background: #ffffff;
    }

    .form label .input.button {
        background: #42b983;
        color: #ffffff;
        padding: 0;
    }

    .form label .input.button:hover {
        background: #42b983;
        padding: 0;
    }

    .form label i {
        position: absolute;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: #42b983;
        left: 0;
        top: 0;
    }

    /*  RTL FIX */

    .rtl .form label i {
        left: auto;
        right: 0;
    }

    .rtl .form label .input {
        padding-left: 0;
        padding-right: 40px;
    }

    .form label .input.button {
        padding: 0;
    }

</style>
