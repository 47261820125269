import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        language: 'ug-CN',
        regions: {
            "zh-CN": [],
            "ug-CN": [],
        },
        regionsWithStateOne: {
            'zh-CN': [],
            'ug-CN': []
        },
        regionsWithStateZero: {
            'zh-CN': [],
            'ug-CN': []
        },
        chosenArea: {}
    },
    mutations: {},
    actions: {},
    modules: {}
})
