<template>
  <div class="container">
    <div class="form">
      <label>
        <i class="fa fa-user"></i>
        <input
          class="input text"
          type="text"
          v-model="contactName"
          :placeholder="$t('m.joinAgent.contact_name')"
        />
      </label>
      <div class="tipX" v-if="tipForName.length > 0">{{ tipForName }}</div>
      <label>
        <i class="fa fa-phone"></i>
        <input
          class="input text"
          type="text"
          v-model="contactPhone"
          :placeholder="$t('m.joinAgent.contact_phone')"
        />
      </label>
      <div class="tipX" v-if="tipForPhone.length > 0">{{ tipForPhone }}</div>
      <label>
        <i class="fa fa-rmb"></i>
        <select
          class="input text"
          ref="capitalSelector"
          v-model="capitalOption"
        >
          <option value="0" selected>{{
            $t("m.joinAgent.contact_capital")
          }}</option>
          <option value="1">{{ $t("m.joinAgent.capital_option_a") }}</option>
          <option value="2">{{ $t("m.joinAgent.capital_option_b") }}</option>
          <option value="3">{{ $t("m.joinAgent.capital_option_c") }}</option>
          <option value="4">{{ $t("m.joinAgent.capital_option_d") }}</option>
        </select>
      </label>
      <div class="tipX" v-if="tipForCapital.length > 0">
        {{ tipForCapital }}
      </div>
      <label>
        <i class="fa fa-building"></i>
        <select
          class="input text"
          ref="regionSelector"
          @change="onRegionChanged"
        >
          <option
            value="0"
            v-if="$store.state.regions[this.$i18n.locale].length === 0"
          >
            {{ $t("m.joinAgent.region") }}
          </option>
          <option
            :value="region.id"
            v-bind:key="index"
            v-for="(region, index) in $store.state.regions[this.$i18n.locale]"
            :selected="region.id === $store.state.chosenArea.city_id"
          >
            {{ region.name }}
          </option>
        </select>
      </label>
      <label>
        <i class="fa fa-building-o"></i>
        <select class="input text" ref="areaSelector" @change="onAreaChange">
          <option
            value="0"
            v-if="$store.state.regions[this.$i18n.locale].length === 0"
          >
            {{ $t("m.joinAgent.area") }}
          </option>
          <option
            :value="area.id"
            v-bind:key="index"
            v-show="area.state === 0"
            v-for="(area, index) in areas"
            :selected="area.id === $store.state.chosenArea.id"
            >{{ area.name }}
          </option>
        </select>
      </label>
      <div class="radio-group" v-if="isArea">
        <label>
          <input
            type="radio"
            id="a1"
            :checked="isArea"
            v-on:change="onAreaCountyChanged($event)"
            name="op"
            value="ناھىيە"
          />
          <span>ناھىيە / رايون</span>
        </label>
        <label>
          <input
            type="radio"
            id="a2"
            :checked="!isArea"
            name="op"
            v-on:change="onAreaCountyChanged($event)"
            value="يېزا"
          />
          <span>يېزا</span>
        </label>
      </div>
      <label v-if="isCounty">
        <i class="fa fa-tree"></i>
        <input
          class="input text"
          v-model="countyName"
          type="text"
          placeholder="يېزا نامىنى كىرگۈزۈڭ"
        />
      </label>
      <div class="tipX" v-if="tipForCounty.length > 0 && isCounty">
        {{ tipForCounty }}
      </div>
      <div class="tip">
        مۇشۇ رايۇننىڭ ۋاكالەتچىلىك زاكالەت پۇلى
        <font color="red">5000</font> يۈەن تاپشۇرغانلار ئالدىن ئويلىشىلىدۇ
      </div>
      <label>
        <button class="input button warning" @click="submitOnly" type="button">
          ئۇچۇر قالدۇراي
        </button>
      </label>
      <label>
        <a href="tel:13899949055" style="width: 100%;display: inline-flex;"
          ><button class="input button" type="button">
            <span class="paddings">ئالاقىلىشاي</span>
            <span class="paddings">13899949055</span>
            <span class="fa fa-phone paddings"></span></button
        ></a>
      </label>
      <ResultModal ref="resultModal" :msg="modalMsg"></ResultModal>
    </div>
  </div>
</template>

<script>
import ResultModal from "../components/ResultModal";
import msg from "../components/msg";
import wx from "weixin-js-sdk";

export default {
  name: "NovemberEvent",
  components: {
    ResultModal,
  },
  data() {
    return {
      interval: null,
      modalMsg: "",
      areas: [],
      agentFee: 0,
      advancedFee: 0,
      isArea: false,
      isCounty: false,
      contactName: "",
      contactPhone: "",
      capitalOption: 0,
      countyName: "",

      tipForName: "",
      tipForPhone: "",
      tipForCapital: "",
      tipForCounty: "",
    };
  },
  watch: {
    areas() {
      this.agentFee = this.$store.state.chosenArea.agent_fee;
      this.advancedFee = this.$store.state.chosenArea.advance_payment;
      this.isArea = this.$store.state.chosenArea.agent_fee < 20000;
    },
    contactName(val) {
      if (val.length < 3) {
        this.tipForName = "ئىسىم-فامىلىڭىزنى تولدۇرۇڭ";
      } else {
        this.tipForName = "";
      }
    },
    contactPhone(val) {
      if (/^1[3456789]\d{9}$/.test(val)) {
        this.tipForPhone = "";
      } else {
        this.tipForPhone = "يانفۇن نۇمۇرىڭىزنى توغرا تولدۇرۇڭ";
      }
    },
    capitalOption(val) {
      if (val == 0) {
        this.tipForCapital = "سالماقچى بولغان مەبلەغ دائېرىسىنى تاللاڭ";
      } else {
        this.tipForCapital = "";
      }
    },
    isCounty(val) {
      if (val) {
        this.agentFee = "8000";
        this.advancedFee = "800";
      } else {
        this.agentFee = "20000";
        this.advancedFee = "2000";
      }
    },
  },
  methods: {
    onAreaCountyChanged(e) {
      this.isCounty = e.target.id === "a2";
    },
    loadAreaData() {
      for (
        let i = 0;
        i < this.$store.state.regions[this.$i18n.locale].length;
        i++
      ) {
        let curRegion = this.$store.state.regions[this.$i18n.locale][i];
        if (curRegion.id === this.$store.state.chosenArea.city_id) {
          this.areas = curRegion.area;
        }
      }
    },
    handleQueryPaymentResult(res) {
      if (res.status === 200 && res.data.status === 200) {
        clearInterval(this.interval);
        this.modalMsg = res.data.msg;
        let modalComponent = this.$refs.resultModal;
        modalComponent.show();
      }
    },
    setQueryPaymentTrigger() {
      let times = 0;
      let postData = {
        mobile: this.contactPhone,
        area_name: this.$refs.areaSelector.options[
          this.$refs.areaSelector.selectedIndex
        ].text,
        county_name: this.isCounty ? this.countyName : "",
      };
      let _this = this;
      this.interval = setInterval(function() {
        times++;
        _this.$axois
          .post("/ug/agent-pay/check-agent-pay", postData)
          .then((res) => {
            _this.handleQueryPaymentResult(res);
          });
        if (times === 10) {
          clearInterval(this.interval);
          location.href = "/";
        }
      }, 2000);
    },
    submitForPay() {
      if (this.formCheck()) {
        let payAmount = this.isCounty
          ? "222"
          : this.$store.state.chosenArea.advance_payment;
        let countyName = this.isCounty ? this.countyName : "";
        let cityName = this.$refs.regionSelector.options[
          this.$refs.regionSelector.selectedIndex
        ].text;
        let areaName = this.$refs.areaSelector.options[
          this.$refs.areaSelector.selectedIndex
        ].text;
        let inless_amount = this.$refs.capitalSelector.options[
          this.$refs.capitalSelector.selectedIndex
        ].text;
        let url =
          "/pages/events/web_pay?amount=" +
          payAmount +
          "&mobile=" +
          this.contactPhone +
          "&name=" +
          this.contactName +
          "&inless_amount=" +
          inless_amount +
          "&county_name=" +
          countyName +
          "&city_name=" +
          cityName +
          "&area_name=" +
          areaName;
        wx.miniProgram.navigateTo({ url: url });
        this.setQueryPaymentTrigger();
      }
    },
    handleResult(res) {
      if (res.status === 200) {
        this.modalMsg = res.data.msg;
        let modalComponent = this.$refs.resultModal;
        modalComponent.show();
      }
    },
    submitOnly() {
      if (this.formCheck()) {
        let countyName = this.isCounty ? this.countyName : "";
        let postData = {
          name: this.contactName,
          mobile: this.contactPhone,
          city_name: this.$refs.regionSelector.options[
            this.$refs.regionSelector.selectedIndex
          ].text,
          area_name: this.$refs.areaSelector.options[
            this.$refs.areaSelector.selectedIndex
          ].text,
          county_name: countyName,
          inless_amount: this.$refs.capitalSelector.options[
            this.$refs.capitalSelector.selectedIndex
          ].text,
        };
        let _this = this;
        this.$axois
          .post("/ug/v1/agent-pay/commit-agent-intention", postData)
          .then((res) => {
            _this.handleResult(res);
          });
      }
    },
    onRegionChanged(val) {
      for (
        let i = 0;
        i < this.$store.state.regions[this.$i18n.locale].length;
        i++
      ) {
        let curRegion = this.$store.state.regions[this.$i18n.locale][i];
        if (curRegion.id == val.target.value) {
          this.areas = curRegion.area;
          this.$store.state.chosenArea = this.areas[0];
          this.$refs.areaSelector.childNodes[1].selected = true;
          this.isArea = false;
          this.isCounty = false;
        }
      }
    },
    formCheck() {
      let ret = true;
      let reg = /^1[3456789]\d{9}$/;

      if (this.contactName.length < 3) {
        this.tipForName = "ئىسىم-فامىلىڭىزنى تولدۇرۇڭ";
        ret = false;
      }

      if (!reg.test(this.contactPhone)) {
        this.tipForPhone = "يانفۇن نۇمۇرىڭىزنى توغرا تولدۇرۇڭ";
        ret = false;
      }

      if (this.capitalOption === 0 || this.capitalOption === "0") {
        this.tipForCapital = "سالماقچى بولغان مەبلەغ دائېرىسىنى تاللاڭ";
        ret = false;
      }

      if (this.isCounty && this.countyName.length <= 0) {
        this.tipForCounty = "يېزا نامىنى كىرگۈزۈڭ";
        ret = false;
      } else {
        this.tipForCounty = "";
      }

      return ret;
    },
    onAreaChange(val) {
      for (let i = 0; i < this.areas.length; i++) {
        let curArea = this.areas[i];
        if (curArea.id == val.target.value) {
          this.$store.state.chosenArea = curArea;
          this.agentFee = this.$store.state.chosenArea.agent_fee;
          this.advancedFee = this.$store.state.chosenArea.advance_payment;
          this.isArea = this.$store.state.chosenArea.agent_fee < 30000;
          this.isCounty =
            this.$store.state.chosenArea.agent_fee > 100 &&
            this.$store.state.chosenArea.agent_fee < 10000;
          break;
        }
      }
    },
    onResultModalOk() {
      location.href = "/";
    },
  },
  mounted() {
    this.loadAreaData();
    msg.$on("resultModalOk", this.onResultModalOk);
  },
};
</script>

<style scoped>
.tip {
  padding: 20px;
  margin-bottom: 20px;
  background: rgba(85, 255, 184, 0.04);
  border: rgba(66, 185, 131, 0.31) 1px solid;
  border-radius: 6px;
  font-size: 13px;
  color: #30a26e;
}

.form {
  padding: 20px;
}

.form label {
  display: flex;
  position: relative;
  height: 40px;
  line-height: 40px;
  margin: 10px 0;
}

.form .tipX {
  color: indianred;
  padding: 0 20px;
}

.form label .input {
  display: block;
  appearance: none;
  flex: 1;
  height: 40px;
  border-radius: 20px;
  background: transparent;
  padding-left: 40px;
  border: none;
}

.form label .input.text {
  background: #f1f2f3;
}

.form label .input.button {
  background: #42b983;
  color: #ffffff;
  padding: 0;
  font-size: 16px;
  line-height: normal;
}

.form label .input.button.warning {
  background: #d49d45;
}

.form label .input.button:hover {
  background: #42b983;
  padding: 0;
}

.form label .input.button.warning:hover {
  background: #ecb149;
}

.form label i {
  position: absolute;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #42b983;
  left: 0;
  top: 0;
}

.radio-group {
  display: flex;
}

.radio-group label {
  flex: 1;
  height: auto;
  line-height: initial;
}

.radio-group label span,
.radio-group label input {
  vertical-align: top;
}

/*  RTL FIX */

.rtl .form label i {
  left: auto;
  right: 0;
}

.rtl .form label .input {
  padding-left: 0;
  padding-right: 40px;
}

.form label .input.button {
  padding: 0;
}
.paddings {
  padding: 0 2px;
}
</style>
