<template>
  <div class="container">
    <div class="scroll-area invisible-scrollbar" ref="scrollArea">
      <router-link class="on" to="/restaurant-recruit-info/area/1" @click.native="switchMe($event)">乌鲁木齐市</router-link>
      <router-link to="/restaurant-recruit-info/area/1" @click.native="switchMe($event)">昌吉市</router-link>
      <router-link to="/restaurant-recruit-info/area/1" @click.native="switchMe($event)">伊宁市</router-link>
      <router-link to="/restaurant-recruit-info/area/1" @click.native="switchMe($event)">库尔勒市</router-link>
      <router-link to="/restaurant-recruit-info/area/1" @click.native="switchMe($event)">吐鲁番市</router-link>
      <router-link to="/restaurant-recruit-info/area/1" @click.native="switchMe($event)">喀什市</router-link>
      <router-link to="/restaurant-recruit-info/area/1" @click.native="switchMe($event)">和田市</router-link>
    </div>
<!--    tmp-->
    <div class="card-list">
      <div class="card-item">
        <div class="card-title">
          <i class="fa fa-institution"></i>
          <span>帕尔拉克大饭店</span>
        </div>
        <div class="card-content">
          <div class="head-line">
            <i class="fa fa-users"></i>
            <span>招募6人</span>
            <a class="tel" href="tel:13579232393">
              <i class="fa fa-phone"></i>
              <span>13579232393</span>
            </a>
          </div>
          <div class="line">
            <h4>招募条件</h4>
            <span>学历不限</span>
            <span>国家二级以上烹饪师证书</span>
            <span>职位要求：18-45周岁，身体健康</span>
            <span>工作时间：早8点-晚9点</span>
          </div>
          <div class="line">
            <h4>招募待遇</h4>
            <span>工资：8000-12000元/月</span>
            <span>加班补助</span>
            <span>饭补</span>
            <span>话补</span>
            <span>公费旅游</span>
          </div>
        </div>
      </div>
      <div class="card-item">
        <div class="card-title">
          <i class="fa fa-institution"></i>
          <span>海尔巴格美食城</span>
        </div>
        <div class="card-content">
          <div class="head-line">
            <i class="fa fa-users"></i>
            <span>招募4人</span>
            <a class="tel" href="tel:13579232393">
              <i class="fa fa-phone"></i>
              <span>13579232394</span>
            </a>
          </div>
          <div class="line">
            <h4>招募条件</h4>
            <span>学历不限</span>
            <span>经验不限</span>
            <span>工作内容：调酒</span>
            <span>职位要求：18-38周岁，身体健康</span>
            <span>工作时间：早8点-晚9点</span>
          </div>
          <div class="line">
            <h4>招募待遇</h4>
            <span>工资：4000-8000元/月</span>
            <span>加班补助</span>
            <span>年底双薪</span>
            <span>交通补助</span>
            <span>饭补</span>
            <span>话补</span>
            <span>公费旅游</span>
          </div>
        </div>
      </div>
      <div class="card-item">
        <div class="card-title">
          <i class="fa fa-institution"></i>
          <span>苏氏牛肉面官（南门店）</span>
        </div>
        <div class="card-content">
          <div class="head-line">
            <i class="fa fa-users"></i>
            <span>招募2人</span>
            <a class="tel" href="tel:13579232393">
              <i class="fa fa-phone"></i>
              <span>13579232395</span>
            </a>
          </div>
          <div class="line">
            <h4>招募条件</h4>
            <span>性别：女</span>
            <span>经验不限</span>
            <span>工作内容：洗完、收拾桌子</span>
            <span>职位要求：26-45周岁，身体健康</span>
            <span>工作时间：早8点-晚9点</span>
          </div>
          <div class="line">
            <h4>招募待遇</h4>
            <span>工资：4000-6000元/月</span>
            <span>加班补助</span>
            <span>交通补助</span>
            <span>饭补</span>
          </div>
        </div>
      </div>
    </div>
<!--    /tmp-->
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'RestaurantRecruitInfo',
  methods: {
    switchMe (e) {
      let scrollArea = this.$refs.scrollArea
      for (let i = 0; i < scrollArea.childNodes.length; i++) {
        let curElement = scrollArea.childNodes[i]
        if (curElement.tagName === 'A' || curElement.tagName === 'a') {
          this.removeClass(curElement, 'on')
        }
      }
      this.addClass(e.currentTarget, 'on')
    },
    removeClass (ele, txt) {
      // eslint-disable-next-line one-var
      let str = ele.className,
        index = str.indexOf(txt)
      if (index > -1) {
        ele.className = str.replace(txt, '')
      }
    },
    addClass (ele, txt) {
      let str = ele.className
      ele.className += str + ' ' + txt
    }
  }
}
</script>

<style scoped>
  .scroll-area {
    background: #ffffff;
    height: 40px;
    line-height: 40px;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .scroll-area a {
    display: inline-block;
    height: 39px;
    color: #2c3e50;
    border-bottom: 1px solid transparent;
    padding: 0 10px;
  }

  .scroll-area a.on {
    color: #42b983;
    border-color: #42b983;
  }

  .card-list{
    padding: 10px;
  }
  .card-list .card-item{
    position: relative;
    min-height: 200px;
    background: #ffffff;
    box-shadow: rgba(0,0,0,0.1) 0 0 2px;
    border-radius: 6px;
    margin-bottom: 20px;
  }
  .card-list .card-item:last-child{
    margin-bottom: 0;
  }
  .card-list .card-item .card-title,.card-list .card-item .card-content{
    padding: 10px;
  }
  .card-list .card-item .card-content{
    padding: 0 10px 10px 10px;
  }
  .card-list .card-item .card-content .head-line{}
  .card-list .card-item .card-content .head-line .tel{
    float: right;
    color: #42b983;
  }
  .card-list .card-item .card-content .line{}
  .card-list .card-item .card-content .line h4{
    margin-bottom: 4px;
  }
  .card-list .card-item .card-content .line span{
    display: inline-block;
    background: rgba(66, 185, 131, 0.05);
    color: #42b983;
    border-radius: 22px;
    padding: 4px 12px;
    margin: 5px;
    font-size: 13px;
  }
/*  RTL FIX*/
  .rtl .card-list .card-item .card-content .head-line .tel{
    float: left;
  }
</style>
