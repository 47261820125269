<template>
    <div class="container">
      <div class="banner">
        <img class="icon" src="../assets/img/mulazim.png" alt="mulazim">
        <div class="slogan">
          <span>{{$t('m.appDownload.slogan')}}</span>
        </div>
      </div>
      <div class="card-list">
        <div class="card-item">
          <div class="card-title">{{$t('m.appDownload.client_terminal')}}</div>
          <div class="card-content">
            <a target="_blank" href="http://file.mulazim.com/upload/apk/20180510/12d5e1b095aa0f9e311cbefc6fc9718f/user-android.apk">
              <i class="fa fa-android"></i>
              <span>{{$t('m.appDownload.android_download')}}</span>
            </a>
            <a target="_blank" href="https://itunes.apple.com/cn/app/mulazim/id1023205348?mt=8">
              <i class="fa fa-apple"></i>
              <span>{{$t('m.appDownload.apple_download')}}</span>
            </a>
          </div>
        </div>
        <div class="card-item">
          <div class="card-title">{{$t('m.appDownload.rider_terminal')}}</div>
          <div class="card-content">
            <a href="javascript:void(0)">
              <i class="fa fa-android"></i>
              <span>{{$t('m.appDownload.android_download')}}</span>
            </a>
            <a href="javascript:void(0)">
              <i class="fa fa-apple"></i>
              <span>{{$t('m.appDownload.apple_download')}}</span>
            </a>
          </div>
        </div>
        <div class="card-item">
          <div class="card-title">{{$t('m.appDownload.restaurant_terminal')}}</div>
          <div class="card-content">
            <a href="javascript:void(0)">
              <i class="fa fa-android"></i>
              <span>{{$t('m.appDownload.android_download')}}</span>
            </a>
            <a class="disabled" href="javascript:void(0)">
              <i class="fa fa-apple"></i>
              <span>{{$t('m.appDownload.apple_download')}}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'AppDownload'
}
</script>

<style scoped>
  .banner{
    background: #42b983 url('../assets/img/mojavee.jpg') no-repeat center;
    background-size: cover;
    height: 10rem;
    text-align: center;
  }
  .banner .icon{
    width: 4rem;
    margin-top: 1rem;
    border: rgba(255,255,255,0.4) 2px solid;
    border-radius: 1rem;
  }
  .banner .slogan{
    margin-top: 1rem;
    color: #2c3e50;
  }
  .banner .slogan span{
    background: rgba(255, 255, 255, 0.9);
    padding: 4px 12px;
    border-radius: 20px;
  }
  .card-list{
    margin: -1rem 10px 10px 10px;
  }
  .card-list .card-item{
    min-height: 160px;
    background: #ffffff;
    -webkit-box-shadow: rgba(0,0,0,0.1) 0 0 2px;
    box-shadow: rgba(0,0,0,0.1) 0 0 2px;
    border-radius: 6px;
    margin-bottom: 20px;
  }
  .card-list .card-item .card-title,.card-list .card-item .card-content{
    padding: 10px;
  }
  .card-list .card-item .card-title{
    padding-bottom: 4px;
    font-weight: bold;
  }
  .card-list .card-item .card-content a{
    display: block;
    width: 90%;
    margin: auto auto 20px auto;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    text-align: center;
    background: #42b983;
    color: #ffffff;
  }
  .card-list .card-item .card-content a i{
    font-size: 16px;
    margin: 0 10px;
    vertical-align: middle;
  }
  .card-list .card-item .card-content a.disabled{
    background: #999999;
  }
</style>
