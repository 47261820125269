<template>
  <div class="container">
    <div class="tip">
      <p>1、请您完善店铺资料，以便我们了解您的情况</p>
      <p>2、认证所需资料不公开给任何组织和个人</p>
      <p>3、审核通过的信息无法修改，如需要帮助请致电客服电话：400-1111-990</p>
    </div>
    <div class="form">
      <label>
        <i class="fa fa-user"></i>
        <input class="input text" type="text" placeholder="联系人姓名">
      </label>
      <label>
        <i class="fa fa-phone"></i>
        <input class="input text" type="phone" placeholder="联系人电话">
      </label>
      <label>
        <i class="fa fa-institution"></i>
        <input class="input text" type="phone" placeholder="店铺名称">
      </label>
      <label>
        <i class="fa fa-location-arrow"></i>
        <input class="input text" type="phone" placeholder="店铺地址">
      </label>
      <div class="card">
        <div class="card-title">营业执照</div>
        <div class="card-content">
          <div class="placeholder">
            <i class="fa fa-image"></i>
            <div class="text">轻按选择图片</div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-title">食品经营许可证</div>
        <div class="card-content">
          <div class="placeholder">
            <i class="fa fa-image"></i>
            <div class="text">轻按选择图片</div>
          </div>
        </div>
      </div>
      <label>
        <button class="input button" type="button">提交</button>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CooperateStep2'
}
</script>

<style scoped>
  .tip{
    margin: 10px;
    padding: 20px;
    background: rgba(85, 255, 184, 0.11);
    border: rgba(66, 185, 131, 0.31) 1px solid;
    border-radius: 6px;
    font-size: 13px;
    color: rgba(66, 185, 131, 0.72) ;
  }
  .form {
    padding: 10px;
  }

  .form label {
    display: flex;
    position: relative;
    height: 40px;
    line-height: 40px;
    margin-bottom: 20px;
  }

  .form label .input {
    display: block;
    appearance: none;
    flex: 1;
    height: 40px;
    border-radius: 20px;
    background: transparent;
    padding-left: 40px;
    border: none;
  }

  .form label .input.text {
    background: #f1f2f3;
  }

  .form label .input.button {
    background: #42b983;
    color: #ffffff;
    padding: 0;
  }

  .form label .input.button:hover {
    background: #42b983;
  }

  .form label i {
    position: absolute;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #42b983;
    left: 0;
    top: 0;
  }
  .form .card{
    min-height: 200px;
    background: #ffffff;
    box-shadow: rgba(0,0,0,0.1) 0 0 2px;
    border-radius: 6px;
    margin-bottom: 20px;
  }
  .form .card .card-title{
    padding: 10px;
  }
  .form .card .card-content{
    padding: 10px;
    text-align: center;
  }
  .form .card .card-content .placeholder{
    margin-top: 20px;
  }
  .form .card .card-content .placeholder i{
    font-size: 40px;
  }
  .form .card .card-content .placeholder .text {
    font-size: 12px;
    padding-top: 4px;
  }
</style>
