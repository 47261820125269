export const m = {
    join_agent: '代理加盟',
    joinAgent:{
        'contact_name': '联系人姓名',
        'contact_phone': '联系人电话',
        'contact_capital': '预期投资',
        'region': '合作地市',
        'area': '合作县区',
        'capital_option_a': '3万-5万',
        'capital_option_b': '5万-10万',
        'capital_option_c': '10万-15万',
        'capital_option_d': '15万以上',
    },
    join_agent_terms: '代理加盟须知',
    cooperate: '商务合作',
    recruit_riders: '招募骑手',
    restaurant_recruit: '餐厅招人',
    app_download: 'APP下载',
    appDownload: {
        'slogan': '美滋来平台感谢您的支持与陪伴！',
        'android_download': '安卓版下载',
        'apple_download': '苹果版下载',
        'client_terminal': '用户客户端',
        'rider_terminal': '配送员客户端',
        'restaurant_terminal': '商家客户端'
    },
    next_step: '下一步',
    confirm: '确定',
    submit: '提交',
    please_select_region: '请选择您要代理的区域',
    please_select_cooperation_region: '请选择您要合作的区域',
    join_terms: {
        about_mulazim : '关于《美滋来》平台'
    }
}
