<template>
  <div class="container">
    <div class="block">
      <div class="word">
        <h4>{{ $t("m.join_terms.about_mulazim") }}</h4>
        <div class="content">
          <p>
            «مۇلازىم» سۇپىسى ـــــ 2015-يىلى شىنجاڭ ئالماس يۇمشاق دېتال چەكلىك
            شىركىتى 3 مىليوندىن ئارتۇق مەبلەغ ۋە بىر يىلدىن ئارتۇق ۋاقىت سەرىپ
            قىلىپ، <font color="#42b983">نۆلدىن باشلاپ</font> تەتقىق قىلىپ ياساپ
            چىققان، ئۇيغۇرچە-خەنزۇچە قوش تىللىق چوڭ تىپتىكى سۇپا. بۇ سۇپا
            2017-يىلى ئۇچۇر مىنىستىرلىقىنىڭ تەكشۈرۈشىدىن ئوڭۇشلۇق ئۆتۈپ، 6
            تۈرلۈك يۇمشاق دېتال بىلىم مۈلۈك ھوقوقىغا ئېرىشكەن.
          </p>
          <img src="../assets/img/licence.jpg" alt="mulazim" />
        </div>
      </div>
    </div>
    <div class="accordion">
      <div class="ac-item" :class="{ on: accordionOne }">
        <div class="ac-h" @click="accordionHeadClick(0)">
          <div class="title">«مۇلازىم» سۇپىسىنىڭ تەركىبى قىسىملىرى</div>
          <div class="icon">
            <i class="fa fa-minus-circle" v-if="accordionOne"></i>
            <i class="fa fa-plus-circle" v-if="!accordionOne"></i>
          </div>
        </div>
        <div class="ac-b" v-if="accordionOne">
          <div class="word">
            <div class="content">
              <p>
                «مۇلازىم» سۇپىسى ـــــ ئىستېمالچىلار ئەپلىرى (ئاندروئىد، ئالما
                نۇسخىسى)، ئۈندىدار ئەپچىقى، H5 بىتى؛ چەۋەندازلار(يەتكۈزگۈچىلەر)
                ئەپلىرى (ئاندروئىد، ئالما نۇسخىسى)؛ دۇكاندارلار ئەپى (ئاندروئىد
                نۇسخىسى)؛ زاكاز باشقۇرۇش ۋە چەۋەندازلارنىڭ ئورۇن بەلگىلەش
                سىستېمسى؛ مۇكەممەل ئارقا سۇپا باشقۇرۇش سىستېمىسى قاتارلىق
                بۆلەكلەردىن تۈزۈلگەن.
              </p>
              <img src="../assets/img/map1.jpg" alt="mulazim" />
              <p>
                1) «مۇلازىم» سۇپىسى-ئىستېمالچىلار (ئابونىتلار) ئەپلىرى
                (ئاندروئىد، ئالما نۇسخىسى)، ئۈندىدار ئەپچىقى، H5 بىتى.
              </p>
              <AppDemoSwiper></AppDemoSwiper>
              <p>
                2) چەۋەندازلار(يەتكۈزگۈچىلەر) ئەپلىرى (ئاندروئىد، ئالما
                نۇسخىسى).
              </p>
              <img src="../assets/img/p/2.jpg" alt="mulazim" />
              <img src="../assets/img/p/3.jpg" alt="mulazim" />
              <p>3) دۇكاندارلار ئەپى (ئاندروئىد نۇسخىسى).</p>
              <img src="../assets/img/p/5.jpg" alt="mulazim" />
              <p>
                4) زاكاز باشقۇرۇش ۋە چەۋەندازلارنىڭ ئورۇن بەلگىلەش سىستېمسى.
              </p>
              <img src="../assets/img/p/6.jpg" alt="mulazim" />
              <p>
                5) مۇكەممەل ئارقا سۇپا باشقۇرۇش سىستېمىسى قاتارلىقلار
                بۆلەكلەردىن تۈزۈلگەن.
              </p>
              <img src="../assets/img/p/7.jpg" alt="mulazim" />
              <p>
                «مۇلازىم» سۇپىسى ـــــ ئۇيغۇرچە-خەنزۇچە قوش تىللىق بولۇپ،
                ئىستېمالچىلار، چەۋەندازلار (يەتكۈزگۈچىلەر)، دۇكاندارلار،
                ۋاكالەتچىلەر تىل ۋە يېزىقنىڭ چەكلىمىسىگە ئۇچرىمايلا، ئەڭ تېز
                سۈرئەتتە تور دۇنياسىدا ئۆز-ئارا ئۇچرىشىپ، بىر-بىرى بىلەن يۈز
                تۇرانە كۆرۈشمەيمۇ، سودا-سېتىقلىرنى توسالغۇسىز ئېلىپ بارالايدۇ.
                «مۇلازىم» سۇپىسى بارلىق كەسىپ ھالقىلىرىنى تولۇق ئۇچۇرلاشتۇرۇپ ۋە
                ئاپتوماتلاشتۇرۇپ، بارلىق ئىشلەتكۈچىلەرگە زور قولايلىق ھەم پايدا
                ئېلىپ كېلىدۇ.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="ac-item" :class="{ on: accordionTwo }">
        <div class="ac-h" @click="accordionHeadClick(1)">
          <div class="title">«مۇلازىم» سۇپىسىنىڭ يارقىن نوقتىلىرى</div>
          <div class="icon">
            <i class="fa fa-minus-circle" v-if="accordionTwo"></i>
            <i class="fa fa-plus-circle" v-if="!accordionTwo"></i>
          </div>
        </div>
        <div class="ac-b" v-if="accordionTwo">
          <div class="word">
            <div class="content">
              <p>
                1) «مۇلازىم» سۇپىسى شىنجاڭ ئالماس يۇمشاق دېتال چەكلىك شىركىتى
                نۆلدىن باشلاپ تەتقىق ياساپ چىققان، تولۇق بىلىم مۈلۈك ھوقوقىغا
                ئىگە مۇكەممەل سۇپا.
              </p>
              <img src="../assets/img/p/8.jpg" alt="mulazim" />
              <p>
                ئالماس شىركىتى دەۋىرنىڭ تەرەققىياتىغا ماس ھالدا ئۆزى مۇستەقىل
                خالىغانچە ئىقتىدار قوشۇپ، يېڭىلاپ، ئەلالاشتۇرۇپ، تېخىمۇ
                مۇكەممەللەشتۈرۈپ ماڭالايدۇ، ئۈچىنجى تەرەپكە بېقىنمايدۇ.
              </p>
              <p>
                2) ئۇيغۇرچە-خەنزۇچە قوش تىللىق-ئىستېمالچىلار بىر كونۇپكا بېسىپلا
                ئۆزلىرى بىلىدىغان تىلغا ئالماشتۇرۇپ توسالغۇسىز ئىشلىتەلەيدۇ
                ھەمدە ئىستېمالچىلارنىڭ دۆلەت تىلى ئۆگىنىشىگە زور ياردەم بىرىدۇ.
              </p>
              <img src="../assets/img/p/lang.gif" alt="mulazim" />
              <p>
                3) ئاپتوماتىك ئورۇن بەلگىلەش-ئىستېمالچىلارنىڭ ئورنىنى ئاپتوماتىك
                بەلگىلەيدۇ ھەمدە ئۇيغۇرچە-خەنزۇچە قوش تىللىق كۆرسىتىپ بىرىدۇ.
              </p>
              <img src="../assets/img/p/9.jpg" alt="mulazim" />
              <p>
                4) ئاۋاز تونۇش-ئىستېمالچىلار قولىدىكى يانفۇنغا ئىزدىمەكچى بولغان
                ئاشخانا ياكى تاماقنى ئۇيغۇرچە ياكى خەنزۇچە دېسىلا، ئۇلارنىڭ
                ئاۋازىنى ئاپتوماتىك تونۇپ، تېز سۈرئەتتە ئىزدەپ تېپىپ بىرىدۇ.
              </p>
              <img src="../assets/img/p/10.jpg" alt="mulazim" />
              <p>
                5) پۇل تۆلەش ئىنتايىن قۇلاي-ئىستېمالچىلار بارمىقىنى مىدىرلىتىپلا
                تور ئارقىلىقلا پۇل تۆلەلەيدۇ.
              </p>

              <p>
                6) دۇكاندارلار مەھسۇلاتلىرىنى توسالغۇسىز تەشۋىق قىلالايدۇ.
                مەھسۇلاتلىرىنى خالىغان يەر، خالىغان ۋاقىتتا «مۇلازىم» سۇپىسىغا
                چىقىرىپ تور دۇنياسىدا كەڭ ھەم توسالغۇسىز تەشۋىقات قىلالايدۇ.
              </p>
              <img src="../assets/img/p/11.jpg" alt="mulazim" />
              <p>
                7) دۇكاندارلار مەھسۇلاتلارنىڭ سېتىش يوللىرىنى خالىغانچە
                كىڭەيتەلەيدۇ. مەھسۇلاتلىرىنى خالىغان يەر، خالىغان ۋاقىتتا
                «مۇلازىم» سۇپىسىغا چىقىرىپ ساتالايدۇ.
              </p>

              <p>
                8) دۇكاندارلار تور ئارقىلىق تاماقلىرىنى سېتىش ئۈچۈن ھىچقانداق
                مەبلەغ سالمايدۇ. دۇكاندارلار «مۇلازىم» سۇپىسىنىڭ دۇكاندار
                نوسخىسىنى ھەقسىز قاچىلىۋېلىپ، زاكازلارنى قۇبۇل قىلىش-قىلماسلىق،
                تاماق تەييار بولغاندا بىر كونۇپكا بىلەنلا چەۋەندازلارغا خەۋەر
                قىلىىش قاتارلىقلارنى قىلالايدۇ.
              </p>

              <p>
                9) دۇكاندارلار ھېساۋاتىنى ۋاقتىدا، ناھايىتى ئېنىق بىلەلەيدۇ.
                «مۇلازىم» سۇپىسىنىڭ دۇكاندار نۇسخىسى ئارقىلىق ئۆزلىرىنىڭ
                ھېساۋاتىنى خالىغان يەر، خالىغان ۋاقىتتا كۆرەلەيدۇ.
              </p>
              <img src="../assets/img/p/12.jpg" alt="mulazim" />
              <p>
                10) دۇكاندارلار ئۆزىگە تەۋە تىجارەت پۇلىنى خالىغان ۋاقىتتا
                نەقلەشتۈرۈپ چىقىرىۋالالايدۇ.
              </p>

              <p>
                11) زاكاز باشقۇرغۇچىلار «مۇلازىم» سۇپىسىنىڭ زاكاز باشقۇرۇش ۋە
                چەۋەندازلارنىڭ ئورۇن بەلگىلەش سىستېمسى ئارقىلىق چۈشكەن
                زاكازلارنى چەۋەندازلارغا ۋاقتىدا تەقسىملەپ بىرىدۇ.
                چەۋەندازلارنىڭ ئورنىنى، قولىدىكى زاكازلارنى خالىغان ۋاقىتتا
                كۆرۈپ تۇرالايدۇ ھەمدە چەۋەندازلارنىڭ خىزمىتىگە نازارەت قىلىدۇ،
                يىتەكچىلىك قىلىدۇ.
              </p>

              <p>
                12) ئارقا سۇپا باشقۇرۇش سىستېمىسى مۇكەممەل ــــ ۋاكالەتچىلەر
                ئارقا سۇپا باشقۇرۇش سىستېمىسى ئارقىلىق زاكاز باشقۇرۇش، زاكاز
                تەقسىملەش، ئاشخانا باشقۇرۇش، مەھسۇلات باشقۇرۇش، ھەرخىل ئېتىبار
                پائالىيەتلەرنى تەشكىللەش، ئادرېس باشقۇرۇش، ئەزا باشقۇرۇش
                قاتارلىق ئىقتىدارلارنى تەمىنلىگەندىن سىرت، ۋاكالەتچىلەر ئۆز
                رايۇنىدىن ئېلان قۇبۇل قىلىپ قىممەت يارىتالايدۇ.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="ac-item" :class="{ on: accordionThree }">
        <div class="ac-h" @click="accordionHeadClick(2)">
          <div class="title">
            «مۇلازىم» سۇپىسىغا ۋاكالەتچى بولۇشنىڭ شەرتلىرى
          </div>
          <div class="icon">
            <i class="fa fa-minus-circle" v-if="accordionThree"></i>
            <i class="fa fa-plus-circle" v-if="!accordionThree"></i>
          </div>
        </div>
        <div class="ac-b" v-if="accordionThree">
          <div class="word">
            <div class="content">
              <p>
                1) «مۇلازىم» سۇپىسىغا ۋاكالەتچى بولماقچى بولغانلار چوقۇم
                «مۇلازىم» شىركىتىنىڭ كارخانا مەدەنىيىتىنى ئىتراپ قىلىشى ھەم
                ئۇزاق مەزگىل ھەمكارلىشىش روھى بولۇش كېرەك.
              </p>
              <p>
                2) ئەنگە ئالدۇرغان شىركەت ياكى يەككە تىجارەت كىنىشكىسى بولۇشى
                ياكى «مۇلازىم» سۇپىسىنىڭ ۋاكالەتچىلىكنى ئېلىپ بولغاندىن شىركەت
                ئەنگە ئالدۇرۇشى ياكى يەككە تىجارەت كىنىشكىسى بىجرىشى كېرەك.
              </p>
              <p>
                3) «مۇلازىم» سۇپىسىنى نورمال ماڭغۇزۇشقا زۆرور بولغان ئىشخانا
                ئورنى بولۇش كېرەك.
              </p>
              <p>
                4) ۋاكالەتچى بولماقچى بولغان رايۇننىڭ يېمەك-ئىچمەك ئادىتى،
                مەدەنىيىتىنى چۈشىنىشى كېرەك.
              </p>
              <p>
                5) ۋاكالەتچى بولماقچى بولغۇچىنىڭ مەلۇم ئىقتىسادى كۈچى بولۇشى
                كېرەك.
              </p>
              <p>
                6) تولۇقسىز ياكى ئۇنىڭدىن يۇقىرى مەدەنىيەت ساپاسى بولۇشى كېرەك.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="ac-item" :class="{ on: accordionFour }">
        <div class="ac-h" @click="accordionHeadClick(3)">
          <div class="title">ۋاكالەتچى بولغانلارنىڭ ئىرىشىدىغانلىرى</div>
          <div class="icon">
            <i class="fa fa-minus-circle" v-if="accordionFour"></i>
            <i class="fa fa-plus-circle" v-if="!accordionFour"></i>
          </div>
        </div>
        <div class="ac-b" v-if="accordionFour">
          <div class="word">
            <div class="content">
              <p>
                1) «مۇلازىم» سۇپىسىنىڭ شۇ رايۇندىكى بىردىنبىر ۋاكالەتچىلىك
                ھوقوقى ۋە يۇقىرى تېخنكىغا تاينىپ تور دۇنياسىدا ئەركىن تىجارەت
                قىلىش ھوقوقىغا ئېرىشىدۇ.
              </p>
              <p>
                2) يۇقىرى تېخنىكىلىق، مۇكەممەل سۇپىنىڭ ئىشلىتىش ھوقوقىغا
                ئېرىشىدۇ، سۇپىنى مۇلازىم باش شىركىتى داۋاملىق يېڭىلاپ، چىققان
                مەسلىلەرنى ۋاقتىدا ھەل قىلىپ ۋاكالەتچىلەرنى غەمدىن خالاس قىلىدۇ.
              </p>
              <p>
                3) «مۇلازىم» سۇپىسىنىڭ شۇ رايۇندىكى بارلىق سۇپا باشقۇرۇش ھوقوقى،
                ئابونتلىرنى باشقۇرۇش ھوقوقىغا ئېرىشىدۇ.
              </p>
              <p>
                4) شۇ رايۇندا ئېلان قۇبۇل قىلىش، «مۇلازىم» سۇپىسىدا ئېلان
                تارقىتىش ۋە ئېلاندىن كېرىم قىلىش ھوقوقىغا ئېرىشىدۇ.
              </p>
              <p>
                5) مۇلازىم باش شىركىتى مەخسۇس مالىيە خادىمى ئاجرىتىپ،
                ۋاكالەتچىلىك رايۇنلىرىدىكى ۋاكالەتچىلەر بىلەن ئاشخانىلارنىڭ
                ھېساۋاتىنى ۋاقتىدا توغرا، ئۆلچەملىك قىلىپ بىرىدۇ. ۋاكالەتچىلەر
                پۈتۈن زىھنىنى بازار ئېچىش، تىجارەتنى تەرەققىي قىلدۇرۇشقا
                قاراتسىلا بولىدۇ.
              </p>
              <p>
                6) مۇلازىم باش شىركەت توردا پۇل تۆلەشتە شەكىللىنىدىغان بارلىق
                چىقىملارنى ئۈستىگە ئېلىپ، ۋاكالەتچىلەرنى غەمدىن خالاس قىلىدۇ.
              </p>
              <p>
                7) مۇلازىم باش شىركەت سۇپا يۈرۈشۈشكە لازىملىق مۇلازىمىتىر ئىجارە
                ھەققى، قسىقا ئۇچۇر ھەققى، مۇلازىمىتىر ئاسراش قاتارلىق بارلىق
                چىقىملارنى ئۈستىگە ئېلىپ، ۋاكالەتچىلەرنى غەمدىن خالاس قىلىدۇ.
              </p>
              <p>
                8) مۇلازىم باش شىركەت مەخسۇس كەسپى خادىم ئەۋەتىپ، ۋاكالەتچىلەر،
                باشقۇرغۇچىلار، چەۋەندازلارغا سۇپىنى قانداق ماڭدۇرۇشقا ئالاقىدار
                تەربىيەلەش ئېلىپ بارىدۇ ھەم ئۇلارنى سۇپىنى يۈرۈشلەشتۈرەلىگىدەك
                سەۋىيەگە يەتكۈزىدۇ.
              </p>
              <p>
                9) سۇپىنى ئىشلىتىشنى ئۆگىنىش قوللانىمىسى ۋە ئالاقىدار
                ماتېرىياللارغا ئېرىشىدۇ.
              </p>
              <p>
                10) سېتىشتىن كېيىنكى مۇلازىمەت تەربىيەسى مۇلازىم باش شىركىتى
                مۇكەممەل بولغان سېتىشتىن كېيىنكى مۇلازىمەت لايىھەسى بىلەن
                تەمىنلەيدۇ، يەنى پىرىنتېردىن چىققان مەسىلىلەرنى تېز سۈرئەتتە ھەل
                قىلىش، يەتكۈزگۈچى خادىم مەسىلىلىرى، مەھسۇلات سۈپىتىدىن مەسىلە
                كۆرۈلۈش ۋە مەھسۇلات تولۇق يەتكۈزۈلمەسلىك مەسىلىلىرىنى بىر تەرەپ
                قىلىش لايىھەسىنى ئۆز ئىچىگە ئالىدۇ.
              </p>
              <p>11) ھەرخىل تەشۋىقات ماترېياللىرىغا ھەقسىز ئېرىشىدۇ.</p>
              <p>
                12) «مۇلازىم» سۇپىسى بازار ئېچىش قوللانمىسى، تەشۋىقات
                ماتېرىياللىرى، بىر تۇتاش خىزمەت كىيىمى ۋە بىر تۇتاش
                ئىشلىتىلىدىغان ئەسۋابلارنىڭ ئۇچۇرىغا ئېرىشىدۇ.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="ac-item" :class="{ on: accordionFive }">
        <div class="ac-h" @click="accordionHeadClick(4)">
          <div class="title">پايدا نىسبىتى</div>
          <div class="icon">
            <i class="fa fa-minus-circle" v-if="accordionFive"></i>
            <i class="fa fa-plus-circle" v-if="!accordionFive"></i>
          </div>
        </div>
        <div class="ac-b" v-if="accordionFive">
          <div class="word">
            <h4>
              «مۇلازىم» سۇپىسىدىكى ھەرقايسى تەرەپلەرنىڭ ئومۇمىي تىجارەت
              سوممىسىدا ئىگەللىگەن نىسىبىتى
            </h4>
            <div class="content">
              <!-- <img src="../assets/img/table1.png" alt="mulazim"> -->
              <table class="table" border="1" cellspacing="0" cellpadding="1">
                <tr class="head">
                  <td colspan="2" class="big">
                    <p>مۇلازىم سۇپىسى</p>
                    <p>(تېجارەت باشلاپ)</p>
                  </td>
                  <td>ئاشخانىلار</td>
                  <td>ۋاكالەتچىلەر</td>
                </tr>

                <tr>
                  <td>تىجارەت باشلىغان كۈنىدىن باشلاپ</td>
                  <td>3.6%</td>
                  <td>80% ~ 85%</td>
                  <td>12% ~ 17%</td>
                </tr>
              </table>

              <p>كۈچلۈك ئىگىلىك تىكلەش ئارزۇسى بارلار ئۈچۈن پۇرسەت كەلدى!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <router-link class="btnNext" to="/agent/info/form">{{$t('m.next_step')}}</router-link> -->
    <router-link class="btnNext" to="/agent/november/event">{{
      $t("m.next_step")
    }}</router-link>
  </div>
</template>
<script>
import AppDemoSwiper from "../components/AppDemoSwiper";
export default {
  name: "JoinTerms",
  data() {
    return {
      accordionOne: true,
      accordionTwo: false,
      accordionThree: false,
      accordionFour: false,
      accordionFive: false,
    };
  },
  methods: {
    accordionHeadClick(index) {
      switch (index) {
        case 0:
          this.accordionOne = !this.accordionOne;
          this.accordionTwo = false;
          this.accordionThree = false;
          this.accordionFour = false;
          this.accordionFive = false;
          break;
        case 1:
          this.accordionTwo = !this.accordionTwo;
          this.accordionOne = false;
          this.accordionThree = false;
          this.accordionFour = false;
          this.accordionFive = false;
          break;
        case 2:
          this.accordionThree = !this.accordionThree;
          this.accordionOne = false;
          this.accordionTwo = false;
          this.accordionFour = false;
          this.accordionFive = false;
          break;
        case 3:
          this.accordionThree = false;
          this.accordionOne = false;
          this.accordionTwo = false;
          this.accordionFour = !this.accordionFour;
          this.accordionFive = false;
          break;
        case 4:
          this.accordionOne = false;
          this.accordionTwo = false;
          this.accordionThree = false;
          this.accordionFour = false;
          this.accordionFive = true;
          break;
        default:
          this.accordionOne = false;
          this.accordionTwo = false;
          this.accordionThree = false;
          this.accordionFour = false;
          this.accordionFive = false;
          break;
      }
    },
  },
  components: {
    AppDemoSwiper,
  },
};
</script>

<style scoped>
.container {
  padding-bottom: 5rem;
}

.swiper-container {
  padding: 20px 0;
}

.block {
  background: white;
  border: rgba(44, 62, 80, 0.1) 1px solid;
  border-left: none;
  border-right: none;
  padding: 20px 10px;
}

.table {
  width: 100%;
  text-align: center;
  height: 300px;
}
.table tr.head {
  font-weight: bold;
}
.table .big {
  width: 50%;
}

.btnNext {
  position: fixed;
  left: 20%;
  right: 20%;
  bottom: 2%;
  z-index: 999;
  width: 60%;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  background: #42b983;
  color: #ffffff;
  border-radius: 40px;
}

.ContentA {
  background: indianred;
  min-height: 200px;
}

.word {
  direction: rtl;
}

.word h4 {
  text-align: center;
  margin-top: 0;
}

.word .content {
  text-align: justify;
  line-height: 20px;
}

.word .content img {
  width: 100%;
  border-radius: 6px;
}

/* accordion */
.accordion .ac-item .ac-h {
  display: flex;
  min-height: 40px;
  line-height: 40px;
  background: #ffffff;
  border-bottom: rgba(44, 62, 80, 0.11) 1px solid;
}

.accordion .ac-item .ac-h .title {
  color: #2e9a69;
  padding: 0 10px;
  font-size: 14px;
  flex: 1;
}

.accordion .ac-item.on .ac-h .title {
  color: #2e9a69;
}

.accordion .ac-item .ac-h .icon {
  color: #2e9a69;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.accordion .ac-item.on .ac-h .icon {
  color: #42b983;
}

.accordion .ac-item .ac-b {
  padding: 16px;
}
</style>
