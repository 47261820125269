import Vue from 'vue'
import App from './App.vue'
import Axios from "axios";
import router from './router'
import store from './store'
import i18n from "./language/i18n"

Vue.config.productionTip = false
Vue.prototype.$axois = Axios
new Vue({
    i18n,
    router,
    store,
    render: h => h(App),
}).$mount('#app')
