import Vue from 'vue'
import VueRouter from 'vue-router'

import JoinAgent from "../views/JoinAgent";
import JoinTerms from "../views/JoinTerms";
import AgentInfo from '../views/AgentInfo'
import Cooperate from '../views/Cooperate'
import CooperateStep1 from '../views/CooperateStep1'
import CooperateStep2 from '../views/CooperateStep2'
import RecruitRiders from '../views/RecruitRiders'
import RestaurantRecruitInfo from '../views/RestaurantRecruitInfo'
import AppDownload from '../views/AppDownload'
import NovemberEvent from "../views/NovemberEvent";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'JoinAgent',
        meta: {
            alias: 'm.join_agent',
            depth: 0
        },
        component: JoinAgent
    },
    {
        path: '/agent/join/terms',
        name: 'JoinTerms',
        meta: {
            alias: 'm.join_agent_terms',
            depth: 1
        },
        component: JoinTerms
    },
    {
        path: '/agent/november/event',
        name: 'NovemberEvent',
        meta: {
            alias: 'm.join_agent',
            depth: 2
        },
        component: NovemberEvent
    },
    {
        path: '/agent/info/form',
        name: 'AgentInfo',
        meta: {
            alias: 'm.join_agent',
            depth: 2
        },
        component: AgentInfo
    },
    {
        path: '/cooperate',
        name: 'Cooperate',
        meta: {
            alias: 'm.cooperate',
            depth: 0
        },
        component: Cooperate
    },
    {
        path: '/cooperate/step1',
        name: 'CooperateStep1',
        meta: {
            alias: 'm.cooperate',
            depth: 1
        },
        component: CooperateStep1
    },
    {
        path: '/cooperate/step2/form',
        name: 'CooperateStep2',
        meta: {
            alias: 'm.cooperate',
            depth: 2
        },
        component: CooperateStep2
    },
    {
        path: '/recruit-riders',
        name: 'RecruitRiders',
        meta: {
            alias: 'm.recruit_riders',
            depth: 0
        },
        component: RecruitRiders
    },
    {
        path: '/restaurant-recruit-info',
        name: 'RestaurantRecruitInfo',
        meta: {
            alias: 'm.restaurant_recruit',
            depth: 0
        },
        children: [
            {
                path: '/restaurant-recruit-info/area/:areaId',
                meta: {
                    alias: 'm.restaurant_recruit',
                    depth: 0
                }
            }
        ],
        component: RestaurantRecruitInfo
    },
    {
        path: '/app-download',
        name: 'AppDownload',
        meta: {
            alias: 'm.app_download',
            depth: 0
        },
        component: AppDownload
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
})

export default router
