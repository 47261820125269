export const m = {
    join_agent: 'ۋاكالەتچى بولۇڭ',
    joinAgent: {
        'contact_name': 'ئالاقىلاشقۇچى ئىسىم-فامىلىسى',
        'contact_phone': 'ئالاقىلىشىش تېلىفونى',
        'contact_capital': 'سالماقچى بولغان مەبلەغ',
        'region': 'شەھەر تاللاڭ',
        'area': 'رايون تاللاڭ',
        'capital_option_a': '3تىن5تۈمەنگىچە',
        'capital_option_b': '5دىن10تۈمەنگىچە',
        'capital_option_c': '10دىن15تۈمەنگىچە',
        'capital_option_d': '15تۈمەندىن يۇقىرى',
    },
    join_agent_terms: 'بىلىشكە تېگىشلىك ئىشلار',
    cooperate: 'دۇكان قوشۇڭ',
    cooperateIn: {

    },
    recruit_riders: 'چەۋەنداز بولامسىز؟',
    restaurant_recruit: 'خىزمەت پۇرسىتى',
    app_download: 'ئەپ چۈشۈرۈش',
    appDownload: {
        'slogan': 'بىزنى قوللىغىنىڭىزغا رەھمەت ئىيتىمىز',
        'android_download': 'ئەندرويد نەشىرىنى چۈشۈرۈش',
        'apple_download': 'ئالما نەشىرىنى چۈشۈرۈش',
        'client_terminal': 'خېرىدارلار تېرمىنالى',
        'rider_terminal': 'چەۋەندازلار تېرمىنالى',
        'restaurant_terminal': 'سودىگەرلەر تېرمىنالى'
    },
    next_step: 'كېيىنكى قەدەم',
    confirm: 'جەزىملەش',
    submit: 'تاپشۇرۇش',
    please_select_region: 'ۋاكالەتچى بولماقچى بولغان رايوننى تاللاڭ',
    please_select_cooperation_region: 'دۇكىنىڭىز تۇرۇشلۇق رايوننى تاللاڭ',
    join_terms: {
        about_mulazim : '«مۇلازىم» سۇپىسى ھەققىدە'
    }
}
