<template>
    <swiper :options="swiperOption" ref="mySwiper">
        <swiper-slide>
            <div class="slider-item">
                <img src="../assets/img/p/1.jpg" alt="mulazim">
            </div>
        </swiper-slide>
        <swiper-slide>
            <div class="slider-item">
                <img src="https://m.mulazim.com/assets/front/images/index-ug.png" alt="mulazim">
            </div>
        </swiper-slide>
        <swiper-slide>
            <div class="slider-item">
                <img src="https://m.mulazim.com/assets/front/images/index-zh.png" alt="mulazim">
            </div>
        </swiper-slide>
        <swiper-slide>
            <div class="slider-item">
                <img src="https://m.mulazim.com/assets/front/images/res-ug.png" alt="mulazim">
            </div>
        </swiper-slide>
        <swiper-slide>
            <div class="slider-item">
                <img src="https://m.mulazim.com/assets/front/images/res-zh.png" alt="mulazim">
            </div>
        </swiper-slide>
    </swiper>
</template>

<script>
    import 'swiper/css/swiper.min.css'
    import {swiper, swiperSlide} from 'vue-awesome-swiper'

    export default {
        name: "AppDemoSwiper",
        components: {
            swiper,
            swiperSlide
        },
        data() {
            return {
                swiperOption: {
                    effect: 'coverflow',
                    grabCursor: true,
                    centeredSlides: true,
                    slidesPerView: 'auto',
                    coverflowEffect: {
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                    },
                    loop: true,
                    autoplay: {
                        delay: 2000,
                        autoplayDisableOnInteraction:false
                    }
                },
                swiper: this.$refs.mySwiper
            }
        }

    }
</script>

<style scoped>
    .swiper-container {
        width: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
        direction: ltr !important;
    }
    .swiper-slide {
        background: #ffffff;
        border-radius: 6px;
        width: 200px;
        height: 355px;

    }
    .swiper-wrapper{
        transition-timing-function:linear!important;
    }
    .slider-item{
        overflow: hidden;
    }
    .slider-item img{
        width: 100%;
    }
</style>
