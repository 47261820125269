<template>
    <transition name="fade">
        <div class="container" v-if="isShown">
            <div class="modal-blocker">
                <div class="modal-box">
                    <div class="in">
                        {{msg}}
                    </div>
                    <div class="button-area">
                        <div class="button button-ok" @click="sendOkMessage">جەزىملەش</div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import msg from "./msg";
    export default {
        name: "ResultModal",
        data() {
            return {
                isShown: false
            }
        },
        methods: {
            show() {
                this.isShown = true
            },
            hide() {
                this.isShown = false
            },
            sendOkMessage(){
                msg.$emit('resultModalOk')
            }
        },
        props: [
            'msg'
        ]

    }
</script>
<style scoped>
    .modal-blocker {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 11000;
        background-color: rgba(0, 0, 0, 0.6);
    }

    .modal-blocker .modal-box {
        display: flex;
        flex-direction: column;
        width: 300px;
        min-height: 100px;
        margin: 40% auto auto auto;
        background: #ffffff;
        border-radius: 6px;
    }

    .modal-blocker .modal-box .in {
        flex: 1;
        padding: 20px;
        text-align: center;
    }

    .modal-blocker .modal-box .button-area {
        padding: 20px;
        text-align: center;
    }

    .modal-blocker .modal-box .button-area .button {
        color: #42b983;
    }
</style>
