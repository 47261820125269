<template>
    <div id="app">
        <div class="main-title">
            <div class="btnBack" v-if="inChild" @click="routeBack">
                <i class="fa fa-arrow-left"></i>
            </div>
            <LangSwitch style="display:none" v-if="!inChild" class="btnSwitchLang" ref="langSwitch" @click.native="switchLang"></LangSwitch>
            <span class="text">{{navTitle}}</span>
        </div>
        <transition name="fade">
            <div class="blocker" v-if="sideBar" @click="toggleMenu"></div>
        </transition>
        <transition name="slide">
            <div class="sidebar" v-if="sideBar">
                <div class="menu">
                    <router-link to="/" @click.native="toggleMenu">{{$t('m.join_agent')}}</router-link>
                    <router-link to="/cooperate" @click.native="toggleMenu">{{$t('m.cooperate')}}</router-link>
                    <router-link to="/recruit-riders" @click.native="toggleMenu">{{$t('m.recruit_riders')}}
                    </router-link>
                    <router-link to="/restaurant-recruit-info" @click.native="toggleMenu">
                        {{$t('m.restaurant_recruit')}}
                    </router-link>
                    <router-link to="/app-download" @click.native="toggleMenu">{{$t('m.app_download')}}</router-link>
                </div>
            </div>
        </transition>
        <div class="btn-menu" :class="{'on':sideBar}" @click="toggleMenu" v-if="false">
            <i class="fa fa-list-ul"></i>
        </div>
        <transition :name="transitionName">
            <router-view class="child-view" :class="{'rtl':rtlDirection}"/>
        </transition>
    </div>
</template>
<script>
    import LangSwitch from "./components/langSwitch";

    export default {
        name: 'App',
        components: {LangSwitch},
        data: function () {
            return {
                rtlDirection: true,
                sideBar: false,
                inChild: false,
                navTitle: '',
                transitionName: 'slide-left',
                lang: this.$store.state.language
            }
        },
        created() {
            this.navTitle = this.$t(this.$route.meta.alias)
            this.rtlDirection = this.$i18n.locale === 'ug-CN'
        },
        beforeRouteUpdate(to, from, next) {
            const toDepth = to.path.split('/').length
            const fromDepth = from.path.split('/').length
            this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
            this.inChild = toDepth > fromDepth
            next()
        },
        watch: {
            $route(to, from) {
                const toDepth = to.path.split('/').length
                const fromDepth = from.path.split('/').length
                this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
                this.inChild = to.meta.depth > 0
                this.navTitle = this.$t(this.$route.meta.alias)
            },
            lang(val) {
                this.navTitle = this.$t(this.$route.meta.alias)
                this.rtlDirection = val === 'ug-CN'
                this.$refs.langSwitch.$data.rtl = this.rtlDirection
            }
        },
        methods: {
            toggleMenu() {
                this.sideBar = !this.sideBar
            },
            routeBack() {
                history.back()
            },
            switchLang() {
                this.$store.state.language = this.$store.state.language === 'zh-CN' ? 'ug-CN' : 'zh-CN'
                this.lang = this.$store.state.language
                this.$i18n.locale = this.$store.state.language
            },
            handleAreaInfoZh(response) {
                if (response.status === 200) {
                    if (response.data.status === 200) {
                        this.$store.state.regions["zh-CN"] = response.data.data
                        this.$store.state.regionsWithStateOne["zh-CN"] = this.filterRegionsByState(response.data.data,1)
                        this.$store.state.regionsWithStateZero["zh-CN"] = this.filterRegionsByState(response.data.data,0)
                    } else {
                        alert(response.data.statusText)
                    }
                }
            },
            handleAreaInfoUg(response) {
                if (response.status === 200) {
                    if (response.data.status === 200) {
                        this.$store.state.regions["ug-CN"] = response.data.data
                        this.$store.state.regionsWithStateOne["ug-CN"] = this.filterRegionsByState(response.data.data,1)
                        this.$store.state.regionsWithStateZero["ug-CN"] = this.filterRegionsByState(response.data.data,0)
                    } else {
                        alert(response.data.statusText)
                    }
                }
            },
            filterRegionsByState(regions, state) {
                let ret = []
                for (let i = 0; i < regions.length; i++) {
                    if (regions[i].state === state ){
                        ret.push(regions[i])
                    }
                }
                return ret
            }
        },
        mounted() {
            this.$axois.get('https://support.mulazim.com/zh/v1/city/city-area').then(this.handleAreaInfoZh);
            this.$axois.get('https://support.mulazim.com/ug/v1/city/city-area').then(this.handleAreaInfoUg);
        }
    }
</script>

<style>
    @import "assets/css/font-awesome.min.css";
    @import "assets/css/ug.css";
    ::-webkit-input-placeholder {
        color:    #c3c3c3;
    }
    ::-moz-placeholder {
        color:    #c3c3c3;
    }
    body {
        margin: 0;
        background: #ffffff;
        font-size: 14px;
        color: #666666;
    }

    a {
        text-decoration: none;
    }

    input, button, select, option {
        outline: none;
        font-family: 'ALKATIP Kitab Tom'
    }

    .invisible-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .container {
        width: 100%;
    }

    .rtl {
        direction: rtl;
    }

    .tip{
        padding: 20px;
        margin: 10px;
        background: rgba(85, 255, 184, 0.04);
        border: rgba(66, 185, 131, 0.31) 1px solid;
        border-radius: 6px;
        font-size: 18px;
        text-align: center;
        color: #30a26e;
    }

    #app {
        font-family: 'ALKATIP Kitab Tom', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #666666;
        padding-top: 3rem;
    }

    .main-title {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 997;
        width: 100%;
        height: 3rem;
        line-height: 3rem;
        text-align: center;
        background: #ffffff;
        box-shadow: rgba(0, 0, 0, 0.1) 0 1px 4px 0;
    }

    .btnBack, .btnSwitchLang {
        position: fixed;
        z-index: 998;
        top: 6px;
        left: 6px;
        width: 3rem;
        height: 3rem;
        line-height: 3rem;
        text-align: center;
    }
    .btnBack{
        left: 0;
        top: 0;
    }

    .fade-enter, .fade-leave-active {
        opacity: 0
    }

    @keyframes slideOutRight {
        from {
            right: 0;
        }
        to {
            right: -40%;
        }
    }

    @keyframes slideInRight {
        from {
            right: -40%;
        }
        to {
            right: 0;
        }
    }

    .child-view {
        position: absolute;
        transition: all .5s cubic-bezier(.55, 0, .1, 1);
    }

    .slide-leave-active {
        animation-name: slideOutRight;
        animation-duration: 300ms;
    }

    .slide-enter-active {
        animation-name: slideInRight;
        animation-duration: 300ms;
    }

    .slide-left-enter, .slide-right-leave-active {
        opacity: 0;
        -webkit-transform: translate(30px, 0);
        transform: translate(30px, 0);
    }

    .slide-left-leave-active, .slide-right-enter {
        opacity: 0;
        -webkit-transform: translate(-30px, 0);
        transform: translate(-30px, 0);
    }

    .btn-menu {
        width: 3rem;
        height: 3rem;
        line-height: 3rem;
        text-align: center;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 1000;
        font-size: 16px;
        transition: ease color 200ms;
    }

    .btn-menu.on {
        color: #ffffff;
    }

    .blocker {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 998;
        background: rgba(44, 62, 80, 0.19);
        transition: ease all 200ms;
    }

    .sidebar {
        width: 40%;
        height: 100%;
        position: fixed;
        z-index: 999;
        top: 0;
        right: 0;
        background: #42b983;
        transition: ease all 200ms;
    }

    .sidebar .menu {
        margin-top: 40px;
    }

    .sidebar .menu a {
        display: block;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: #ffffff;
    }
</style>
