<template>
    <div class="container">
        <div class="tip">{{$t('m.please_select_cooperation_region')}}</div>
        <div class="area-list">
            <div class="area-item" v-bind:key="index" v-for="(region,index) in regions[this.$i18n.locale]">
                <div class="area-title">{{region.name}}</div>
                <div class="small-area-list">
                    <router-link to="/cooperate/step1" v-show="area.state === 1" v-bind:key="j"
                                 v-for="(area,j) in region.area"
                                 @click.native.capture="pickArea(area)">{{area.name}}
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Cooperate',
        data() {
            return {
                regions: {
                    'zh-CN': [],
                    'ug-CN': []
                },
            }
        },
        created() {
            this.regions = this.filterEmptyRegions(this.$store.state.regionsWithStateOne)
        },
        methods: {
            pickArea(area) {
                this.$store.state.chosenArea = area
            },
            filterEmptyRegions(regions) {
                let ret = {
                    'zh-CN': [],
                    'ug-CN': []
                }

                for (let i = 0; i < regions['ug-CN'].length; i++) {
                    let curRegion = regions['ug-CN'][i]
                    if (curRegion.area.length > 0) {
                        ret['ug-CN'].push(curRegion)
                        ret['zh-CN'].push(curRegion = regions['zh-CN'][i])
                    }
                }
                return ret
            }
        }
    }
</script>

<style scoped>
    .area-list {
        padding: 10px;
    }

    .area-list .area-item {
        padding: 20px;
        background: #ffffff;
        position: relative;
        border-radius: 6px;
        margin: 20px 0px 40px 0;
    }

    .area-list .area-item .area-title {
        position: absolute;
        height: 30px;
        line-height: 30px;
        background: #ffffff;
        color: #42b983;
        border-radius: 6px 6px 0 0;
        padding: 0 20px;
        left: 10px;
        top: -20px;
    }

    .area-list .area-item .small-area-list a {
        display: inline-block;
        text-align: center;
        padding: 10px 30px;
        margin: 8px;
        border: #eee 1px solid;
        border-radius: 6px;
        color: #2c3e50;
    }

    /*  RTL FIX*/
    .rtl .area-list .area-item .area-title {
        left: auto;
        right: 10px;
    }
</style>
