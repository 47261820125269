import Vue from 'vue'
import Vue18n from 'vue-i18n'
Vue.use(Vue18n);

const i18n = new Vue18n({
    locale: 'ug-CN',
    messages: {
        'zh-CN': require('./locates/zh'),
        'ug-CN': require('./locates/ug')
    }
})

export default i18n
