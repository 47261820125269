<template>
    <div class="track">
        <div class="item">汉</div>
        <div class="item">ئۇ</div>
        <div class="indicator" :class="{'on':rtl}">{{indicatorText}}</div>
    </div>
</template>

<script>
    export default {
        name: "langSwitch",
        data: function () {
            return {
                rtl: true,
                indicatorText: 'ئۇ'
            };
        },
        watch:{
            rtl(val){
                this.indicatorText = val ? 'ئۇ' : '汉';
            }
        }
    }
</script>

<style scoped>
    .track {
        display: flex;
        width: 80px;
        height: 30px;
        padding: 2px;
        line-height: 32px;
        border-radius: 15px;
        text-align: center;
        background: #efefef;
        overflow: hidden;
    }

    .track .item {
        flex: 1;
        color: #2c3e50;
        border-radius: 15px;
    }

    .track .indicator{
        position: absolute;
        width: 40px;
        height: 30px;
        border-radius: 20px;
        background: #42b983;
        color: #fff;
        left: 2px;
        transition: ease all 200ms;
    }
    .track .indicator.on{
        left: 42px;
    }

</style>
