<template>
    <div class="container">
      <div class="tip">开店前请准备好以下6种图片资料</div>
      <div class="card-list">
        <div class="card-item">
          <div class="card-number">1</div>
          <div class="card-title">营业执照</div>
          <div class="card-content">资质照片 或者 其它要求</div>
        </div>
        <div class="card-item">
          <div class="card-number">2</div>
          <div class="card-title">食品经营许可证</div>
          <div class="card-content">资质照片 或者 其它要求</div>
        </div>
        <div class="card-item">
          <div class="card-number">3</div>
          <div class="card-title">门头照（含店名）</div>
          <div class="card-content">资质照片 或者 其它要求</div>
        </div>
        <div class="card-item">
          <div class="card-number">4</div>
          <div class="card-title">店内环境图</div>
          <div class="card-content">资质照片 或者 其它要求</div>
        </div>
        <div class="card-item">
          <div class="card-number">5</div>
          <div class="card-title">法人身份证正面</div>
          <div class="card-content">资质照片 或者 其它要求</div>
        </div>
        <div class="card-item">
          <div class="card-number">6</div>
          <div class="card-title">法人身份证背面</div>
          <div class="card-content">资质照片 或者 其它要求</div>
        </div>
      </div>
      <div class="bottom">
        <router-link class="btnNext" to="/cooperate/step2/form">都准备好了，下一步</router-link>
      </div>
    </div>
</template>

<script>
export default {
  name: 'CooperateStep1'
}
</script>

<style scoped>
.card-list{
  padding: 10px;
}
.card-list .card-item{
  position: relative;
  min-height: 200px;
  background: #ffffff;
  box-shadow: rgba(0,0,0,0.1) 0 0 2px;
  border-radius: 6px;
  margin-bottom: 20px;
}
.card-list .card-item:last-child{
  margin-bottom: 0;
}

.card-list .card-item .card-number{
  position: absolute;
  left: -0.6rem;
  top: -0.6rem;
  width: 1.2rem;
  height: 1.2rem;
  line-height: 1.2rem;
  text-align: center;
  border-radius: 1rem;
  background: #42b983;
  font-size: 12px;
  color: #ffffff;
}
.card-list .card-item .card-title,.card-list .card-item .card-content{
  padding: 10px;
}
.card-list .card-item .card-content{
  text-align: center;
}
.bottom{
  display: flex;
  padding: 10px;
}
.btnNext{
  display: block;
  height: 2.6rem;
  line-height: 2.6rem;
  text-align: center;
  border-radius: 2rem;
  flex: 1;
  appearance: none;
  border: none;
  padding: 0;
  background: #42b983;
  color: #ffffff;

}
</style>
