<template>
    <div class="container">
      <div class="card-list">
        <div class="card-item">
          <div class="card-title">
            <i class="fa fa-building"></i>
            <span>乌鲁木齐市</span>
          </div>
          <div class="card-content">
            <div class="head-line">
              <i class="fa fa-users"></i>
              <span>招募12人</span>
              <a class="tel" href="tel:13579232393">
                <i class="fa fa-phone"></i>
                <span>13579232393</span>
              </a>
            </div>
            <div class="line">
              <h4>招募条件</h4>
              <span>学历不限</span>
              <span>经验不限</span>
              <span>工作内容：负责外卖配送</span>
              <span>职位要求：18-45周岁，身体健康</span>
              <span>工作时间：早8点-晚9点 饭点工作</span>
            </div>
            <div class="line">
              <h4>招募待遇</h4>
              <span>工资：4000-8000元/月</span>
              <span>加班补助</span>
              <span>年底双薪</span>
              <span>交通补助</span>
              <span>饭补</span>
              <span>话补</span>
              <span>公费旅游</span>
            </div>
          </div>
        </div>
        <div class="card-item">
          <div class="card-title">
            <i class="fa fa-building"></i>
            <span>库尔勒市</span>
          </div>
          <div class="card-content">
            <div class="head-line">
              <i class="fa fa-users"></i>
              <span>招募22人</span>
              <a class="tel" href="tel:13579232393">
                <i class="fa fa-phone"></i>
                <span>13579232394</span>
              </a>
            </div>
            <div class="line">
              <h4>招募条件</h4>
              <span>学历不限</span>
              <span>经验不限</span>
              <span>工作内容：负责外卖配送</span>
              <span>职位要求：18-45周岁，身体健康</span>
              <span>工作时间：早8点-晚9点 饭点工作</span>
            </div>
            <div class="line">
              <h4>招募待遇</h4>
              <span>工资：4000-8000元/月</span>
              <span>加班补助</span>
              <span>年底双薪</span>
              <span>交通补助</span>
              <span>饭补</span>
              <span>话补</span>
              <span>公费旅游</span>
            </div>
          </div>
        </div>
        <div class="card-item">
          <div class="card-title">
            <i class="fa fa-building"></i>
            <span>伊宁市</span>
          </div>
          <div class="card-content">
            <div class="head-line">
              <i class="fa fa-users"></i>
              <span>招募6人</span>
              <a class="tel" href="tel:13579232393">
                <i class="fa fa-phone"></i>
                <span>13579232395</span>
              </a>
            </div>
            <div class="line">
              <h4>招募条件</h4>
              <span>学历不限</span>
              <span>经验不限</span>
              <span>工作内容：负责外卖配送</span>
              <span>职位要求：18-45周岁，身体健康</span>
              <span>工作时间：早8点-晚9点 饭点工作</span>
            </div>
            <div class="line">
              <h4>招募待遇</h4>
              <span>工资：4000-8000元/月</span>
              <span>加班补助</span>
              <span>年底双薪</span>
              <span>交通补助</span>
              <span>饭补</span>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'RecruitRiders'
}
</script>

<style scoped>
.card-list{
  padding: 10px;
}
.card-list .card-item{
  position: relative;
  min-height: 200px;
  background: #ffffff;
  box-shadow: rgba(0,0,0,0.1) 0 0 2px;
  border-radius: 6px;
  margin-bottom: 20px;
}
.card-list .card-item:last-child{
  margin-bottom: 0;
}
.card-list .card-item .card-title,.card-list .card-item .card-content{
  padding: 10px;
}
.card-list .card-item .card-content{
  padding: 0 10px 10px 10px;
}
.card-list .card-item .card-content .head-line{}
.card-list .card-item .card-content .head-line .tel{
  float: right;
  color: #42b983;
}
.card-list .card-item .card-content .line{}
.card-list .card-item .card-content .line h4{
  margin-bottom: 4px;
}
.card-list .card-item .card-content .line span{
  display: inline-block;
  background: rgba(66, 185, 131, 0.05);
  color: #42b983;
  border-radius: 22px;
  padding: 4px 12px;
  margin: 5px;
  font-size: 13px;
}
/*  RTL FIX */
.rtl .card-list .card-item .card-content .head-line .tel{
  float: left;
}
</style>
